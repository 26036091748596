import { AssetInUnit } from '../../../domain/unit.types';
import * as t from 'io-ts';
import { decode } from './iotsDecoder';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendUrls } from './config';
import { accessToken } from '../../configuration/tokenHandling/accessToken';

const assetInUnit = t.type(
    {
        asset_id: t.string,
        unit_id: t.string,
    },
    'assetInUnit'
);
const assetsInUnitsCodec = t.array(assetInUnit);

type AssetsInUnitsAPIResponse = t.TypeOf<typeof assetsInUnitsCodec>;
type AssetInUnitAPIResponse = t.TypeOf<typeof assetInUnit>;
const decodeAssetsInUnitsApiResponse = (parsedObject: unknown): AssetsInUnitsAPIResponse =>
    decode(parsedObject, assetsInUnitsCodec);

const mapToAssetInUnit = (data: AssetInUnitAPIResponse): AssetInUnit => {
    return {
        assetId: data.asset_id,
        unitId: data.unit_id,
    };
};

const mapToAssetsInUnits = (data: AssetsInUnitsAPIResponse): AssetInUnit[] => {
    return data.map((it) => mapToAssetInUnit(it));
};

const transformAssetsInUnits = (response: unknown): AssetInUnit[] => {
    const decoded = decodeAssetsInUnitsApiResponse(response);
    return mapToAssetsInUnits(decoded);
};

export const assetsInUnitsApi = createApi({
    reducerPath: 'assetsInUnitsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrls.UNITS_API,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAssetsInUnits: builder.query<AssetInUnit[], void>({
            query: () => '/assets',
            transformResponse: (response: unknown) => transformAssetsInUnits(response),
        }),
    }),
});

export const { useGetAssetsInUnitsQuery } = assetsInUnitsApi;
