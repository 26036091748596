import { DraggedAsset, Unit, UnitRequest } from '../../../domain/unit.types';
import * as t from 'io-ts';
import { decode } from './iotsDecoder';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendUrls } from './config';
import { accessToken } from '../../configuration/tokenHandling/accessToken';

const draggedAsset = t.type(
    {
        asset_id: t.string,
        added_at: t.string,
    },
    'draggedAsset'
);
const unitCodec = t.type(
    {
        id: t.string,
        account_id: t.string,
        dragging_asset_id: t.string,
        dragged_assets: t.array(draggedAsset),
        created_at: t.string,
        modified_at: t.string,
    },
    'unitApiResponse'
);

interface UnitApiRequest {
    id: string;
    dragging_asset_id: string;
    dragged_asset_ids: string[];
}

type UnitsApiResponse = t.TypeOf<typeof unitCodec>;
type DraggedAssetApiResponse = t.TypeOf<typeof draggedAsset>;
const decodeUnitsApiResponse = (parsedObject: unknown): UnitsApiResponse => decode(parsedObject, unitCodec);

const mapToDraggedAsset = (data: DraggedAssetApiResponse): DraggedAsset => {
    return {
        assetId: data.asset_id,
        addedAt: data.added_at,
    };
};

const mapToUnit = (data: UnitsApiResponse): Unit => {
    return {
        id: data.id,
        accountId: data.account_id,
        draggingAssetId: data.dragging_asset_id,
        draggedAssets: data.dragged_assets.map((it) => mapToDraggedAsset(it)),
    };
};

const transformUnits = (response: unknown): Unit => {
    const decoded = decodeUnitsApiResponse(response);
    return mapToUnit(decoded);
};

const mapToUnitRequestApi = (request: UnitRequest): UnitApiRequest => {
    return {
        id: request.id,
        dragging_asset_id: request.draggingAssetId,
        dragged_asset_ids: request.draggedAssetIds,
    };
};

export const unitsApi = createApi({
    reducerPath: 'unitsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrls.UNITS_API,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('Content-Type', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUnit: builder.query<Unit, string>({
            query: (unitId) => `/units/${unitId}`,
            transformResponse: (response: unknown) => transformUnits(response),
        }),
        putUnit: builder.mutation<Unit, { id: string; data: UnitRequest }>({
            query: ({ id, data }) => ({
                url: `units/${id}`,
                method: 'PUT',
                body: mapToUnitRequestApi(data),
            }),
            transformResponse: (response: unknown) => transformUnits(response),
        }),
        deleteUnit: builder.mutation<void, string>({
            query: (id) => ({
                url: `units/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { useGetUnitQuery, usePutUnitMutation, useDeleteUnitMutation } = unitsApi;
