import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { backendUrls } from './config';
import * as t from 'io-ts';
import { decode } from './iotsDecoder';
import { accessToken } from '../../configuration/tokenHandling/accessToken';
import { Asset, AssetType } from '../../../domain/asset.types';
import { isOfType } from '../../../common/typescriptUtils/typeManipulation';

interface BasicResponseStructure {
    _links?: {
        next?: {
            href: string | undefined;
        };
    };
    items: any[];
}

const link = t.type({
    href: t.string,
});

const selfLink = t.type({
    self: link,
});

const nextLink = t.partial({
    next: link,
});

const ResourceLinkList = t.intersection([selfLink, nextLink]);
const embedded = t.partial({
    tags: t.type({
        items: t.array(
            t.type(
                {
                    id: t.string,
                },
                'tag'
            )
        ),
    }),
});

const assetRequiredFields = t.type(
    {
        id: t.string,
        account_id: t.string,
        name: t.string,
        status: t.string,
        type: t.string,
    },
    'assetRequiredFields'
);

const assetOptionalFields = t.partial(
    {
        identification: t.union([t.string, t.null]),
        identification_type: t.union([t.string, t.null]),
        _embedded: embedded,
    },
    'assetOptionalFields'
);

const asset = t.intersection([assetRequiredFields, assetOptionalFields]);

const apiResponseAssetListCodec = t.intersection(
    [
        t.partial({
            _links: ResourceLinkList,
        }),
        t.type({
            items: t.array(asset),
        }),
    ],
    'apiResponseAssetList'
);

type ApiResponseAssets = BasicResponseStructure & t.TypeOf<typeof apiResponseAssetListCodec>;
type ApiResponseAsset = t.TypeOf<typeof asset>;

const decodeApiResponseAssets = (parsedObject: unknown): ApiResponseAssets =>
    decode(parsedObject, apiResponseAssetListCodec);

const mapApiAssetTypeToAssetType = (apiAssetType: string): AssetType | undefined => {
    switch (apiAssetType) {
        case 'truck':
            return AssetType.TRUCK;
        case 'trailer':
            return AssetType.TRAILER;
        case 'bus':
            return AssetType.BUS;
        case 'van':
            return AssetType.VAN;
        default:
            console.warn(`Unknown asset type found: ${apiAssetType}`);
            return undefined;
    }
};

const mapApiAssetToAsset = (apiAsset: ApiResponseAsset): Asset | undefined => {
    const assetFromApiAsset = {
        id: apiAsset.id,
        name: apiAsset.name,
        type: mapApiAssetTypeToAssetType(apiAsset.type),
    };
    return isOfType(assetFromApiAsset) ? assetFromApiAsset : undefined;
};

const mapToAssets = (data: ApiResponseAssets): Asset[] => {
    return data.items.map((it) => mapApiAssetToAsset(it)).filter((it): it is Asset => typeof it !== 'undefined');
};

export const assetsApi = createApi({
    reducerPath: 'assetsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrls.ASSETS_API,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAssets: builder.query<Asset[], void>({
            // eslint-disable-next-line @typescript-eslint/naming-convention,prefer-arrow/prefer-arrow-functions
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const MAX_BACKEND_CALLS = 1000;
                let calls = 0;
                const items: Asset[] = [];
                let nextUrl: string | undefined = '/assets';
                while (calls < MAX_BACKEND_CALLS && nextUrl) {
                    calls++;
                    const { data, error } = await fetchWithBQ({ url: nextUrl, params: { status: 'active' } });
                    if (error) {
                        return { error };
                    }
                    const apiResponseAssets = decodeApiResponseAssets(data);
                    const assets = mapToAssets(apiResponseAssets);
                    // eslint-disable-next-line no-underscore-dangle
                    nextUrl = apiResponseAssets._links?.next?.href;

                    items.push(...assets);
                }
                return { data: items };
            },
        }),
    }),
});

export const { useGetAssetsQuery: useGetAssets } = assetsApi;
