import { combineReducers } from 'redux';
import { tokenHandlingReducer } from '../configuration/tokenHandling/reducer';
import { configReducer } from '../configuration/setup/configReducer';
import { langReducer } from '../configuration/lang/reducer';
import { loginReducer } from '../configuration/login/reducer';
import { productActivationsApi } from './api/api.productActivations';
import { widgetReducer } from './widget/widget.redux';
import { assetsApi } from './api/api.assets';
import { unitsApi } from './api/api.units';
import { assetsInUnitsApi } from './api/api.unitAssets';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rootReducer = () =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
        login: loginReducer,
        tokenHandling: tokenHandlingReducer,
        unitWidget: widgetReducer,
        productActivationsApi: productActivationsApi.reducer,
        assetsApi: assetsApi.reducer,
        unitsApi: unitsApi.reducer,
        assetsInUnitsApi: assetsInUnitsApi.reducer,
    });

const tmp = rootReducer();
export type State = ReturnType<typeof tmp>;
