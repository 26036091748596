export enum AssetType {
    TRUCK = 'truck',
    TRAILER = 'trailer',
    BUS = 'bus',
    VAN = 'van',
}

export interface Asset {
    id: string;
    type: AssetType;
    name: string;
}
