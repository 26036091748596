import { datadogLogs } from '@datadog/browser-logs';

import cloneDeepWith from 'lodash/cloneDeepWith';

const tokenStringReplacer = (val) => {
    if (val && typeof val.replace === 'function') {
        val = val.replace(/access_token=[^&]*/, 'access_token=REDACTED');
        val = val.replace(/id_token=[^&]*/, 'id_token=REDACTED');
        return val;
    }
};

if (import.meta.env.PROD) {
    datadogLogs.init({
        clientToken: 'pubc2adefe1d36da38ee65bb2d6dfc13f8c',
        forwardErrorsToLogs: true,
        site: 'datadoghq.eu',
        sampleRate: 100,
        version: '0.2.0',
        service: 'unit-administration-web',
        env: 'prod',
        beforeSend: (obj) => cloneDeepWith(obj, tokenStringReplacer),
    });
    datadogLogs.addLoggerGlobalContext('account_id', '000859226773');
    datadogLogs.addLoggerGlobalContext('team', 'rio-team-transport-two');
    datadogLogs.addLoggerGlobalContext('team_identifier', 'rio-team-transport-two');
}
