import './polyfills';

import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { handleLoginRedirect, main, store } from './application/configuration';
import { config } from './application/config';
import { ErrorBoundary } from './application/app/ErrorBoundary';
import './application/configuration/setup/datadog';
import { createRoot } from 'react-dom/client';

const AppContainer = lazy(() => import(/* webpackChunkName: "SPA" */ './application/app/UnitWidget.container'));

const renderApplication = (): void => {
    const container = document.getElementById('root');
    if (!container) {
        throw new Error('Failed to find the root element');
    }
    const root = createRoot(container);

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <Suspense fallback={''}>
                        <Routes>
                            <Route path={'/'} element={<AppContainer />} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (import.meta.env.PROD) {
    main(renderApplication);
} else if (import.meta.env.DEV) {
    import('../mocks/browser').then(({ startWorker }) => startWorker()).then(() => main(renderApplication));
}
