export enum Products {
    GEO = 'GEO',
}

export enum ProductStatus {
    ACTIVE = 'Active',
    PENDING = 'Pending',
}

export interface AssetProduct {
    product: Products;
    statusChangeDate: string;
    status: ProductStatus;
}

export interface AssetBookings {
    [key: string]: AssetProduct[];
}
