import { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
    children?: ReactNode;
}

// componentDidCatch is not supported by lifecycle hooks yet in React 16.12.0
export class ErrorBoundary extends Component<ErrorBoundaryProps, { error: Error | null }> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null,
        };
    }

    componentDidCatch(error: Error): void {
        this.setState({ error });
    }

    render(): ReactNode {
        if (this.state.error) {
            return null;
        }
        return this.props.children;
    }
}
