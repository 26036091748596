import { configureStore as configureStoreReduxToolkit } from '@reduxjs/toolkit';
import { rootReducer } from '../../redux/reducers';
import { useDispatch } from 'react-redux';
import reduceReducers from 'reduce-reducers';
import { productActivationsApi } from '../../redux/api/api.productActivations';
import { assetsApi } from '../../redux/api/api.assets';
import { unitsApi } from '../../redux/api/api.units';
import { assetsInUnitsApi } from '../../redux/api/api.unitAssets';

const reducer = rootReducer();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const configureStore = () =>
    configureStoreReduxToolkit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reducer: reduceReducers(reducer),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
                .concat(productActivationsApi.middleware)
                .concat(assetsApi.middleware)
                .concat(unitsApi.middleware)
                .concat(assetsInUnitsApi.middleware),
    });

export const store = configureStore();

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
