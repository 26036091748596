import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: WidgetState = {
    widgetId: undefined,
    visibleWidgets: [],
    selectedAssetId: undefined,
};

interface WidgetState {
    widgetId?: string;
    visibleWidgets: string[];
    selectedAssetId?: string;
}

const { reducer, actions } = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        setWidgetId: (state: WidgetState, action: PayloadAction<string>): void => {
            state.widgetId = action.payload;
        },
        setVisibleWidgets: (state: WidgetState, action: PayloadAction<string[]>): void => {
            state.visibleWidgets = action.payload;
        },
        setSelectedAssetId: (state: WidgetState, action: PayloadAction<string | undefined>): void => {
            state.selectedAssetId = action.payload;
        },
    },
});

export const widgetActions = actions;
export const widgetReducer = reducer;
