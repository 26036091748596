const asBool = (value: string | undefined): boolean => value === 'true';

interface ConfigState {
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockUser?: string;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
}

export const config: ConfigState = {
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '1e3c7d43-81c4-41f1-9322-5351bfe5c2c2',
        oauthScope: ['openid'],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockUser: import.meta.env.VITE_LOGIN_MOCK_USER,
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
};
